<template>
  <div class="queryVin">
    <div style="background: linear-gradient(to right, #1677ff, #2f99ff)">
      <nav-bar
        title="查VIN码"
        leftColor="#fff"
        style="
          background: linear-gradient(to right, #1677ff, #2f99ff);
          color: #fff;
        "
      ></nav-bar>

      <div class="search">
        <van-search
          v-model="vinFomat"
          placeholder="请输入17位车架号"
          shape
          maxlength="17"
          :clearable="false"
        >
          <template #left-icon>
            <div style="visibility: hidden; width: 0"></div>
          </template>
          <template #right-icon>
            <div class="flex">
              <div class="icon">
                <van-uploader accept="image/*" :after-read="afterRead">
                  <van-icon name="photo-o" />
                </van-uploader>
              </div>
              <span class="line"></span>
              <div class="icon" @click="search"><van-icon name="search" /></div>
            </div>
          </template>
        </van-search>
      </div>
      <div class="now" v-show="choiceCar && choiceCar.modelName">
        <div class="card">
          <div class="car flex">
            <img :src="choiceCar && choiceCar.carLogoUrl" alt="" />
            <div>{{ choiceCar && choiceCar.modelName }}</div>
          </div>
          <div class="clear" @click="clearNow">清空内容</div>
        </div>
      </div>
    </div>

    <div style="height: 10px; background: #f8f8f8"></div>
    <div class="history flex" v-if="!isResult">
      <div class="title">历史查询</div>
      <div class="clear" @click="clearHistory" v-show="0 in history">
        <img src="@/assets/delete.png" alt="" style="width: 17px" />
      </div>
    </div>
    <div v-if="!isResult">
      <div v-if="0 in history">
        <div
          class="list"
          v-for="(item, index) in history"
          :key="index"
          @click="search(item.vin)"
        >
          <img :src="item.carLogoUrl" alt="" />
          <div class="name">
            <div>
              {{ item.modelName }}
            </div>
            <div class="flex">
              <div>{{ item.vin }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; margin: 80px auto">
        暂无历史记录
      </div>
    </div>
    <div v-else class="carConfig">
      <div class="title">车辆配置</div>
      <div v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index">
          <div class="flex" v-for="(key, value, index) in item" :key="index">
            <div>{{ value }}：</div>
            <div>{{ key }}</div>
          </div>
        </div>
      </div>
      <div v-else style="margin: 80px; text-align: center">
        该VIN码未解析到数据
      </div>
    </div>
    <canvas
      style="display: none"
      id="canvas"
      class="canvas"
      width="0"
      height="0"
    ></canvas>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import {
  standardQueryVinForEpc,
  standardGetEpcHistorySelectForVin,
  getLlqCarInfo,
  standardDeleteEpcHistorySelectForVin,
} from "@/api";
import { compress } from "@/utils/compress";
import { Search, Icon, Toast, Dialog, Uploader } from "vant";
export default {
  name: "queryVin",
  components: {
    NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      vin: "",
      choiceCar: "",
      isResult: false, //识别成功标识
      list: {},
      history: [],
    };
  },
  computed: {
    //vin码输入小写转大写
    vinFomat: {
      get: function () {
        return this.vin;
      },
      set: function (val) {
        this.vin = val.toUpperCase();
      },
    },
  },
  methods: {
    doUpload(file) {
      console.log(file.size);
      Toast.loading({
        message: "图片识别中...",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      var that = this;
      var reader = new FileReader();
      reader.readAsDataURL(file); //转化二进制流，异步方法
      reader.onload = function () {
        //完成后this.result为二进制流
        var base64Str = this.result;
        axios({
          timeout: 6000,
          method: "POST",
          url: `https://vin.market.alicloudapi.com/api/predict/ocr_vin`,
          data: JSON.stringify({
            image: base64Str,
          }),
          headers: {
            "content-type": "application/octet-stream; charset=utf-8",
            Authorization: "APPCODE 6b312937f2fb4704ad37e582092af075",
          },
        })
          .then((res) => {
            if (res.data.success) {
              that.vin = res.data.vin;
              // var reg = /^[A-HJ-NPR-Z\d]{8}[X\d][A-HJ-NPR-Z\d]{3}\d{5}$/;
              // if(reg.test(res.data.vin)){
              //   that.vin = res.data.vin;
              //   that.search();
              // }else{
              //   Toast.clear();
              //   Dialog.alert({
              //     message: "识别到的VIN码格式不正确，请上传清晰的照片",
              //   });
              // }
            } else {
              Dialog.alert({
                message: "未识别到VIN码",
              });
            }
            Toast.clear();
          })
          .catch((error) => {
            Toast.clear();
            if (error.message.includes("timeout")) {
              Dialog.alert({
                message: "请求超时",
              });
            } else {
              Dialog.alert({
                message: "未识别到VIN码或识别出错，请重试",
              });
            }
          });
      };
    },
    //图片上传
    afterRead(file) {
      if (130000 > file.file.size) {
        this.doUpload(file.file);
      } else {
        compress(file.content, "canvas").then((file) => {
          this.doUpload(file);
        });
      }
    },
    //搜索按钮点击
    search(vin) {
      if (vin && !vin.target) {
        this.vin = vin;
      }
      if (this.vin) {
        var reg = /^[A-HJ-NPR-Z\d]{8}[X\d][A-HJ-NPR-Z\d]{3}\d{5}$/;
        if (!reg.test(this.vin)) {
          Toast.clear();
          Dialog.alert({ message: "VIN码格式不正确", title: "提示" });
          return;
        }
        standardQueryVinForEpc({
          vin: this.vin,
          userId: sessionStorage.getItem("userId"),
        }).then((res) => {
          if (res && res.data.data.flag == "ok") {
            Toast.clear();
            this.choiceCar = res.data.data;
            this.getCarConfig();
          }
        });
      } else {
        Toast.clear();
        Dialog.alert({ message: "请先输入vin码", title: "提示" });
      }
    },
    //查询车辆配置信息
    getCarConfig() {
      getLlqCarInfo({ code: this.choiceCar.carModelCode, vin: this.vin }).then(
        (res) => {
          console.log(res);
          this.list = res.data.data;
          this.isResult = true;
        }
      );
    },
    //清空当前选择
    clearNow() {
      this.getHistory();
      this.choiceCar = "";
      this.vin = "";
      this.isResult = false;
    },
    //清空历史记录
    clearHistory() {
      var that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要删除历史记录吗？",
      }).then(() => {
        standardDeleteEpcHistorySelectForVin({
          userId: sessionStorage.getItem("userId"),
        }).then((res) => {
          that.history = [];
        });
      });
    },
    //获取历史记录
    getHistory() {
      standardGetEpcHistorySelectForVin({
        userId: sessionStorage.getItem("userId"),
      }).then((res) => {
        this.history = res.data.data.data || [];
      });
    },
  },
  mounted() {
    this.getHistory();
  },
};
</script>

<style lang="less" scoepd>
.queryVin {
  .van-search {
    background: linear-gradient(to right, #1677ff, #2f99ff);
  }
  .van-nav-bar__left i {
    color: #fff !important;
  }
  .van-ellipsis span {
    font-size: 18px;
    font-weight: 500;
    color: #fff !important;
  }
  .carConfig {
    padding: 10px 15px;
    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .flex {
      font-size: 12px;
      margin: 10px 0;
      div {
        &:first-child {
          width: 120px;
        }
        &:last-child {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
  .list {
    display: flex;
    align-items: center;
    padding: 5px 15px 0;
    height: 80px;
    word-break: break-all;
    border-bottom: 1px solid #f4f4f4;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .name {
      color: #464646;
      .flex {
        color: #969696;
        display: flex;
        align-items: center;
      }
    }
  }
  .now {
    padding: 10px 15px;
    .card {
      background: linear-gradient(to right, #3187fe, #439eff);
      border-radius: 5px;
      .car {
        padding: 12px 17px;
        word-break: break-all;
        justify-content: unset;
        color: #fff;
        img {
          margin-right: 10px;
          width: 33px;
          height: 33px;
          border-radius: 50%;
        }
      }
      .clear {
        color: #fff;
        height: 37px;
        line-height: 37px;
        text-align: center;
        border-top: 1px dashed #fff;
      }
    }
  }
  .history {
    margin-top: 10px;
    padding: 0 15px;
    height: 50px;
    line-height: 50px;
    .title {
      font-weight: bold;
      font-size: 16px;
      color: #969696;
    }
    .clear {
      color: #fe9a23;
    }
  }

  .search {
    padding-top: 50px;
    .flex {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line {
        height: 20px;
        margin: 0 10px;
        width: 1px;
        background: #ccc;
      }
      .icon {
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .canvas {
    display: none;
  }
}
</style>